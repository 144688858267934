import React from 'react';
import Layout from '@common/Layout';
import NavbarSubpages from '@common/Navbar/NavbarSubpages';
import Story from '@sections/Story';
import Footer from '@sections/Footer';
import SEO from '@common/SEO';

const SecondPage = () => (
  <Layout>
    <SEO/>
    <NavbarSubpages />
    <Layout>
    <Story />
    </Layout>
    <Footer />
  </Layout>
);

export default SecondPage;