import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { GlobalLink } from '@components/Link';

const Art = styled.figure`
  width: 50%;
  margin: 0;
  
  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  width: 100%;
  padding: 50px 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const Story = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_showcase: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "office-woanna" }
        ) {
          childImageSharp {
            fluid(maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (

      <Section id="download">
        <Container style={{ position: 'relative',height: '100%' }}>

        <div style={{ margin: `3rem auto`, maxWidth: 600 }}>
          <h3>Why are we building rocketbatch? </h3>
          <br />
          <h1>Unleashing a new wave of productivity</h1>
          <br />
          <p>
            <br />
          A collaboration and communication tool with modern and fresh usability.
          </p>
        </div>

        <br />
        <br />
        <br />
        <p>
            We are on the verge of a new productivity revolution. Machine learning and artificial 
            intelligence are allowing new ways of solving daily tasks. 
        </p>
        <br />
        <br />
        <br />
        <p>
        Yet a few challenges lay ahead of using ai. First and foremost, using the right tool for the right task.
        A lot of things needs personal skills. But tools should be smart, fast and intuitive.
        rocketbatch was built with a simple idea in mind: <strong>let's build beautiful productivity software which is
        simple and powerful at the same time so user actually use it</strong>.
        </p>        
        


          <Container>
            <Grid>
              
              <Text>
                <h4>
                rocketbatch is based in St. Gallen, Switzerland.
                </h4>
                <br />
                <h3>
                  We are technologists, craftsman and design loving developers building
                  the tools we want to use.
                </h3>
                <p>
                  <br />
                  <br />
                  <br />

                {/*
                <StyledExternalLink href="https://team.rocketbatch.com/">
                    Start in Browser &nbsp;&#x2794;
                </StyledExternalLink>
                 */}
                </p>
              </Text>
              <Art>
                <Img fluid={data.art_showcase.childImageSharp.fluid} />
              </Art>
            </Grid>
          </Container>
         

        </Container>
      </Section>
    )}
  />
);


const StyledExternalLink = styled.a`
  color: #0056E5;
  text-decoration: none;
  font-size: 20px;
    line-height: 30px;
  &:hover {
    color: ${props => props.theme.color.link};
  }
`;

export default Story;